import { RRule, rrulestr } from "rrule";
import moment from "moment-timezone";

function setUntilToEndOfDay(untilDate, eventTimeZone) {
  let date = new Date(untilDate);
  date = convertToTimeZone(date, eventTimeZone); // KST로 변환

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59); // 해당 날짜의 마지막 시간으로 설정
}

// UTC 시간을 특정 시간대로 변환하는 함수
function convertToTimeZone(dateTime, timeZone) {
  return new Date(
    new Intl.DateTimeFormat("en-US", {
      timeZone: timeZone, // 특정 시간대로 변환
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    }).format(new Date(dateTime))
  );
}

function generateInstanceId(event, instanceStart, isAllday) {
  if (!instanceStart) {
    console.error("instanceStart가 없습니다.");
    return null;
  }

  // instanceStart가 Date 객체인지 확인하고 UTC 시간대로 변환
  const instanceDate = instanceStart instanceof Date ? instanceStart : new Date(instanceStart);
  const year = instanceDate.getUTCFullYear(); // UTC 기준 연도
  const month = String(instanceDate.getUTCMonth() + 1).padStart(2, "0"); // UTC 기준 월 (0부터 시작하므로 +1)
  let day = String(instanceDate.getDate()).padStart(2, "0"); // UTC 기준 일

  let hours, minutes, seconds;
  if (!isAllday) {
    day = String(instanceDate.getUTCDate()).padStart(2, "0"); // UTC 기준 일
    hours = String(instanceDate.getUTCHours()).padStart(2, "0"); // UTC 기준 시간
    minutes = String(instanceDate.getUTCMinutes()).padStart(2, "0"); // UTC 기준 분
    seconds = String(instanceDate.getUTCSeconds()).padStart(2, "0"); // UTC 기준 초
  }

  // 포맷팅된 날짜 문자열 (UTC 기준)
  const formattedDate = isAllday
    ? `${year}${month}${day}`
    : `${year}${month}${day}T${hours}${minutes}${seconds}Z`;

  // event.id 또는 recurringEventId에서 _가 포함된 경우 앞부분만 사용
  let baseId = event.recurringEventId || event.id;
  if (baseId.includes("_")) {
    baseId = baseId.split("_")[0]; // _ 앞부분만 사용
  }

  // 최종 ID 생성
  return `${baseId}_${formattedDate}`;
}

// 반복 이벤트를 개별 인스턴스로 확장
export function expandRecurringEvent(event, calendarViewBaseDate) {
  const isAllDay = event.start.timeZone || event.startTimeZone ? false : true;

  let rruleString = event.recurrence ? event.recurrence[0] : null;
  let lastDay = new Date(
    calendarViewBaseDate.getFullYear(),
    calendarViewBaseDate.getMonth() + 2,
    0
  );

  if (!rruleString) return []; // RRULE이 없으면 빈 배열 반환

  // allDay일때 확장
  if (isAllDay) {
    const eventTimeZone = event.start.timeZone || "Asia/Seoul"; // 사용자 시간대 설정

    // 1. 시작 날짜를 사용자 시간대에서 계산 (시간 정보가 없으므로, 날짜 정보만 사용)
    let startDate = moment.tz(event.start.date, eventTimeZone).startOf("day");

    // 2. UTC 오프셋 계산
    const utcOffset = startDate.utcOffset() / 60; // 오프셋을 시간 단위로 계산
    const cutoffHour = utcOffset >= 0 ? utcOffset : 24 + utcOffset;

    // 3. UTC로 변환하여 rruleString의 DTSTART 설정 (시간을 유지한 채 변환)
    const dtstart = startDate.utc().format("YYYYMMDD[T]HHmmss[Z]");
    rruleString = `DTSTART:${dtstart}\n${rruleString}`;
    const rule = rrulestr(rruleString);

    // 4. UTC 기준으로 반복 날짜를 계산
    let eventStartDate = startDate.utc().toDate();
    let untilDate = rule.options.until ? setUntilToEndOfDay(rule.options.until, "UTC") : lastDay;

    // 5. rrule.between으로 날짜 전체 계산
    let dates = rule.between(eventStartDate, untilDate, true); // inc=true로 첫날 포함

    // 6. 각 occurrenceDate를 사용자 시간대로 변환하여 요일을 맞춤
    dates = dates.map((occurrenceDate) => {
      let instanceDate = moment.tz(new Date(occurrenceDate), "UTC").tz(eventTimeZone);

      if (rruleString.includes("BYDAY")) {
        instanceDate = moment.tz(new Date(occurrenceDate), "UTC").add(-1, "days").tz(eventTimeZone);

        if (instanceDate < startDate) {
          return;
        }
      }

      return instanceDate.format("YYYY-MM-DD");
    });

    // 각 occurrenceDate를 사용해 이벤트 인스턴스 생성
    return dates.map((formattedDate) => {
      const newId = generateInstanceId(event, moment(formattedDate).toDate(), true);

      return {
        ...event,
        id: newId,
        start: { date: formattedDate },
        end: { date: formattedDate },
        recurringEventId: event.id,
        iCalUID: event.iCalUID,
      };
    });
  }

  try {
    const eventTimeZone = event.start.timeZone || "Asia/Seoul"; // 사용자의 시간대 설정

    // 1. 시작 날짜 + 시간을 사용자 시간대에서 계산
    let startDateTime = moment.tz(event.start.dateTime, eventTimeZone).startOf("day");

    // 2. 사용자 시간대의 UTC 오프셋 계산
    const utcOffset = startDateTime.utcOffset() / 60; // 오프셋을 시간 단위로 계산

    // 3. 각 시간대에 맞는 기준 시간을 UTC 오프셋으로 설정 (UTC 오프셋에 맞춰 기준 시간 설정)
    const cutoffHour = utcOffset >= 0 ? utcOffset : 24 + utcOffset;

    // 4. UTC로 변환하여 rruleString의 DTSTART 설정 (시간을 유지한 채 변환)
    const dtstart = startDateTime.utc().format("YYYYMMDD[T]HHmmss[Z]");
    rruleString = `DTSTART:${dtstart}\n${rruleString}`;
    const rule = rrulestr(rruleString);

    // 5. UTC 기준으로 반복 날짜를 계산
    let eventStartDate = startDateTime.utc().toDate();
    let untilDate = rule.options.until ? setUntilToEndOfDay(rule.options.until, "UTC") : lastDay;

    // 6. rrule.between으로 날짜 전체 계산
    let dates = rule.between(eventStartDate, untilDate, true); // inc=true로 첫날 포함

    // 7. 각 occurrenceDate를 사용자 시간대로 변환하여 요일을 맞춤
    dates = dates.filter((occurrenceDate) => {
      let instanceDate = moment.tz(new Date(occurrenceDate), "UTC").tz(eventTimeZone);

      // 8. 사용자 시간대 기준으로, 기준 시간(UTC 오프셋 기준)보다 이전인 경우 하루를 빼서 요일을 보정
      const hours = instanceDate.hours();
      if (hours < cutoffHour) {
        if (rruleString.includes("BYDAY")) {
          instanceDate = instanceDate.subtract(1, "days");
        }
      }

      // 9. 날짜 보정 후 instanceDate가 startDateTime보다 이전인지 확인해 배열에서 삭제
      if (instanceDate.isBefore(startDateTime, "day")) {
        return false;
      }
      return true;
    });

    // 10. 각 occurrenceDate를 사용자 시간대로 변환하여 요일을 맞춤
    return dates.map((occurrenceDate) => {
      // occurrenceDate는 UTC로 계산되었으므로 이를 사용자의 시간대로 변환
      let instanceDate = moment.tz(new Date(occurrenceDate), "UTC").tz(eventTimeZone);

      // 11. 사용자 시간대 기준으로, 기준 시간(UTC 오프셋 기준)보다 이전인 경우 하루를 빼서 요일을 보정
      const hours = instanceDate.hours();
      if (hours < cutoffHour) {
        if (rruleString.includes("BYDAY")) {
          instanceDate = instanceDate.subtract(1, "days");
        }
      }

      const finalInstanceDate = instanceDate.toDate();

      // 10. 원래 이벤트 시작 시간과 종료 시간을 사용자의 시간대에서 설정
      const originalStart = moment.tz(event.start.dateTime, eventTimeZone);
      const instanceStart = moment
        .tz(finalInstanceDate, eventTimeZone)
        .set({
          hour: originalStart.hours(),
          minute: originalStart.minutes(),
          second: originalStart.seconds(),
        })
        .toDate();

      const originalEnd = moment.tz(event.end.dateTime, eventTimeZone);
      const instanceEnd = moment
        .tz(finalInstanceDate, eventTimeZone)
        .set({
          hour: originalEnd.hours(),
          minute: originalEnd.minutes(),
          second: originalEnd.seconds(),
        })
        .toDate();

      const newId = generateInstanceId(event, instanceStart, false);

      return {
        ...event,
        id: newId,
        start: {
          ...event.start,
          dateTime: instanceStart.toISOString(),
        },
        end: {
          ...event.end,
          dateTime: instanceEnd.toISOString(),
        },
        recurringEventId: event.id,
        iCalUID: event.iCalUID,
      };
    });
  } catch (error) {
    console.error("RRULE 처리 중 오류 발생:", error);
    return [];
  }
}
