import { useRef, useState } from "react";
import { ReactComponent as IntegrationSunSet } from "../../assets/Notice/IntegrationSunset.svg";
import styles from "./Notice.module.css";
import { loadFromLocalStorage, saveToLocalStorage } from "../../utils/localStorage/localStorage";

const Notice = () => {
  // 로컬스토리지 값 가져오기
  const noticeOverlayRef = useRef(null);
  const hasSeenNotice = loadFromLocalStorage("hasSeenNotice");
  const [isVisible, setIsVisible] = useState(hasSeenNotice !== true);

  // 팝업을 닫으면 로컬 스토리지에 기록 저장
  const handleClose = () => {
    setIsVisible(false);
    saveToLocalStorage("hasSeenNotice", true);
  };

  // 배경을 클릭하면 팝업 닫기
  const handleBackgroundClick = (e) => {
    if (e.target === noticeOverlayRef.current) {
      handleClose();
    }
  };

  if (!isVisible) return null;

  return (
    <div ref={noticeOverlayRef} className={styles.overlay} onClick={handleBackgroundClick}>
      <div className={styles.noticePopup}>
        <IntegrationSunSet className={styles.noticeImg} />
        <div className={styles.noticeMessage}>
          <div className={styles.title}>
            <h3 className={styles.noticeSmallTitle}>Sunset Notice</h3>
            <h1>Integrations (Gmail, Slack, Jira)</h1>
          </div>
          <div className={styles.body}>
            <p>
              2024년 1월, 저희는 Slack, Gmail, Jira와 같은 여러 외부 툴을 연동하여 모든 업무를 한
              곳에서 관리할 수 있는 제품을 만들고자 Integration 기능을 출시하였습니다. 그러나 시간이
              지나면서 저희는 단순히 여러 외부 툴을 모아주는 대시보드보다는, 사용자가 더 빠르게
              계획을 세우고 실행에 집중할 수 있는 제품을 만드는 것이야 말로 생산성을 높일 수 있는
              제품의 핵심 가치임을 다시 확인할 수 있었습니다.
            </p>
            <p>
              이러한 방향성에 맞추어 외부 툴과의 연동 기능은 더 이상 최적의 선택이 아니라고
              판단하였으며, 이에 따라 해당 기능을 2024년 10월 7일부로 잠정 종료하기로 결정했습니다.
              앞으로도 저희는 효율적으로 일하고 몰입할 수 있는 경험을 제공하는 제품을 만들도록
              노력하겠습니다.
            </p>
            <p>감사합니다.</p>
          </div>

          <button onClick={handleClose}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default Notice;
